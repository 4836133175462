import { Auth } from "aws-amplify";
import { getDataFromConfig, loadConfig } from "./getDataFromConfig";

let fichierConfig = await loadConfig().catch((err) => {
	console.error("Failed to load config:", err);
});

async function updateIdpUserAttributes(lang) {
	// déclenché uniquement si inscription/connexion Google ODV
	let redirectURI = localStorage.getItem("client-redirect-uri");
	const foundSiteKey = getDataFromConfig(
		fichierConfig,
		redirectURI,
		"siteKey"
	);

	const customAttributes = {
		"custom:virbacEmailLanguage": lang,
		"custom:virbacAccountLocked": "false",
		"custom:virbacDefaultSite": foundSiteKey || "",
	};

	// Get the current authenticated user
	await Auth.currentAuthenticatedUser().then(async (user) => {
		// Update user attributes
		await Auth.updateUserAttributes(user, customAttributes).catch(
			(error) => {
				console.error("Error updating custom attributes:", error);
			}
		);
	});
}
export default updateIdpUserAttributes;
