
export async function loadConfig() {
    try {
        return (await import(`../configuration/config.json`)).default;
    } catch (error) {
        console.warn(`No config file :`, error);
    }
}

export const getDataFromConfig = (fichierConfig, redirectURI, data) => {

    const applications = fichierConfig.applications;
    for (const key in applications) {
        if (applications[key].sites.find(obj => obj.redirectURI === redirectURI)) {
            return applications[key].sites.find(obj => obj.redirectURI === redirectURI)[data];
        }
    }
}

