import { I18n } from "@aws-amplify/core";

const formFields = (lang, siteKey, email) => {
	let emailObject;
	let usernameObject;

	if (email !== undefined && email !== "") {
		emailObject = {
			value: email,
			labelHidden: true,
			isRequired: true,
			readOnly: true,
			order: 1,
		};
		usernameObject = {
			value: email,
			labelHidden: true,
			readOnly: true,
			order: 1,
		};
	} else {
		emailObject = {
			placeholder: I18n.get("Enter your email"),
			labelHidden: true,
			isRequired: true,
			order: 1,
		};
		usernameObject = {
			placeholder: I18n.get("Enter your email"),
			labelHidden: true,
			order: 1,
		};
	}
	return {
		signIn: {
			username: usernameObject,
			password: {
				placeholder: I18n.get("Enter your Password"),
				labelHidden: true,
				isRequired: true,
				order: 2,
			},
		},
		signUp: {
			email: emailObject,
			password: {
				placeholder: I18n.get("Enter your Password"),
				labelHidden: true,
				isRequired: true,
				order: 2,
			},
			confirm_password: {
				placeholder: I18n.get("Please confirm your Password"),
				labelHidden: true,
				isRequired: true,
				order: 3,
			},
			phone_number: {
				type: "hidden",
				display: "none",
				isRequired: false,
			},
			"custom:virbacEmailLanguage": {
				type: "hidden",
				value: lang,
				display: "none",
			},
			"custom:virbacAccountLocked": {
				type: "hidden",
				value: false,
				display: "none",
			},
			"custom:virbacDefaultSite": {
				type: "hidden",
				value: siteKey,
				display: "none",
			},
		},
		forceNewPassword: {
			password: {
				labelHidden: true,
				placeholder: I18n.get("Enter your Password"),
			},
		},
		resetPassword: {
			username: {
				labelHidden: true,
				placeholder: I18n.get("Enter your email"),
			},
		},
		confirmResetPassword: {
			confirmation_code: {
				labelHidden: true,
				placeholder: I18n.get("Enter your Confirmation Code"),
				isRequired: false,
			},
			confirm_password: {
				labelHidden: true,
				placeholder: I18n.get("Please confirm your Password"),
			},
		},
		setupTotp: {
			confirmation_code: {
				labelHidden: true,
				placeholder: I18n.get("Enter your Confirmation Code"),
				isRequired: false,
			},
		},
		confirmSignIn: {
			confirmation_code: {
				labelHidden: true,
				placeholder: I18n.get("Enter your Confirmation Code"),
				isRequired: false,
			},
		},
		confirmSignUp: {
			confirmation_code: {
				labelHidden: true,
				placeholder: I18n.get("Enter your Confirmation Code"),
				isRequired: false,
			},
		},
	};
};

const services = {
	async validateCustomSignUp(formData) {
		if (!formData.acknowledgement) {
			return {
				acknowledgement: I18n.get(
					"You must agree to the Terms & Conditions"
				),
			};
		}
		if (formData.email.search(/^\S+@\S+\.\S+$/g) !== 0) {
			return {
				email: I18n.get("The email entered is not valid"),
			};
		}
	},
};

export { formFields, services };
