import { View, Image, Heading } from "@aws-amplify/ui-react";

import { I18n } from "@aws-amplify/core";

import logoLight from "../assets/Logos/logo-light.png";
import { SignInHeader, SignInFooter } from "../components/Login/SignIn";
import {
	SignUpFooter,
	SignUpFormFields,
	SignUpHeader,
} from "../components/Register/SignUp";

const components = (
	redirectDomain,
	hideRegister,
	forceRegister,
	SAMLIdPs,
	termsAndConditionsURL
) => {
	return {
		Header() {
			return (
				<View textAlign="center" margin="40px">
					<a href={redirectDomain}>
						<Image
							alt="Amplify logo"
							src={logoLight}
							width="130px"
						/>
					</a>
				</View>
			);
		},

		SignIn: {
			Header() {
				return <SignInHeader SAMLIdPs={SAMLIdPs} />;
			},
			Footer() {
				return (
					<SignInFooter
						hideRegister={hideRegister}
						forceRegister={forceRegister}
					/>
				);
			},
		},

		SignUp: {
			Header() {
				return <SignUpHeader SAMLIdPs={SAMLIdPs} />;
			},
			FormFields() {
				return (
					<SignUpFormFields
						termsAndConditionsURL={termsAndConditionsURL}
					/>
				);
			},
			Footer() {
				return <SignUpFooter forceRegister={forceRegister} />;
			},
		},
		ConfirmSignUp: {
			Header() {
				return (
					<Heading level={3}>{I18n.get("Confirm Sign Up")}</Heading>
				);
			},
		},
		ConfirmSignIn: {
			Header() {
				return <Heading level={3}>{I18n.get("Confirm")}</Heading>;
			},
		},
		ForgotPassword: {
			Header() {
				return (
					<Heading level={3}>{I18n.get("Reset Password")}</Heading>
				);
			},
		},
		ConfirmResetPassword: {
			Header() {
				return <Heading level={3}>{I18n.get("Confirm")}</Heading>;
			},
		},
	};
};

export default components;
